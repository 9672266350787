import { IssueDetailDto } from "../../../../store/generated/issue";
import IssueType from "../../types/IssueType";
import StatusType from "../../../../common/types/StatusType";
import { PriorityType } from "../../types/PriorityType";
import Sider from "antd/es/layout/Sider";
import { TicketPill } from "./TicketPill";
import { EditableProjectPill } from "./EditableProjectPill";
import { EditableIssueTypePill } from "./EditableIssueTypePill";
import { EpicSelectPill } from "./EpicSelectPill";
import { EditableStatusTypePill } from "./EditableStatusTypePill";
import { EditablePriorityTypePill } from "./EditablePriorityTypePill";
import React from "react";
import { IssueUserSelect } from "./IssueUserSelect";
import { Collapse, CollapseProps, Divider, Flex, Form, FormInstance } from "antd";
import { EditableStoryPointsInput } from "./EditableStoryPointsInput";
import { EditableSprintInput } from "./EditableSprintInput";
import { IssueTeamSelect } from "./IssueTeamSelect";

export function IssueDetailsSidebar(props: {
    formInstance: FormInstance;
    backgroundColor: string;
    editedIssue: IssueDetailDto;
    onValueChange: (value: any, field: keyof IssueDetailDto) => void;
}) {
    return (
        <Form
            form={props.formInstance}
            layout="horizontal"
            autoComplete="off"
            style={{ backgroundColor: props.backgroundColor }}
        >
            <Sider style={{ backgroundColor: props.backgroundColor, paddingRight: 20, textAlign: "right" }} width={300}>
                <Flex flex={1} gap={8} vertical={false} align="baseline">
                    <Form.Item name="project" style={{ marginBottom: 0 }}>
                        <EditableProjectPill
                            project={props.editedIssue?.project}
                            onProjectUpdate={(value) => props.onValueChange(value, "project")}
                        />
                    </Form.Item>
                    <Form.Item name="team" style={{ marginBottom: 0 }}>
                        <IssueTeamSelect
                            team={props.editedIssue.teamDto}
                            onSelect={(value) => props.onValueChange(value, "teamDto")}
                        />
                    </Form.Item>
                    <Flex justify="end" flex={1}>
                        <TicketPill issueKey={props.editedIssue?.projectIssueKeyedLabel!!} />
                    </Flex>
                </Flex>

                <Divider />
                <>
                    {props.editedIssue?.issueType !== IssueType.Epic &&
                    props.editedIssue?.issueType !== IssueType.Initiative ? (
                        <Form.Item label="Epic" name="epic">
                            <EpicSelectPill
                                epic={props.editedIssue?.epic}
                                projectKey={props.editedIssue?.project?.projectKey!!}
                                onEpicUpdate={(value) => props.onValueChange(value, "epic")}
                            />
                        </Form.Item>
                    ) : undefined}
                    <Form.Item label="Issue Type" name="issueType">
                        <EditableIssueTypePill
                            issueType={props.editedIssue?.issueType ?? IssueType.Task}
                            onIssueTypeUpdate={(value) => props.onValueChange(value, "issueType")}
                        />
                    </Form.Item>
                    <Form.Item label="Status" name="status">
                        <EditableStatusTypePill
                            status={props.editedIssue?.status ?? StatusType.Todo}
                            onStatusTypeUpdate={(value) => props.onValueChange(value, "status")}
                        />
                    </Form.Item>
                    <Form.Item label="Priority" name="priority">
                        <EditablePriorityTypePill
                            priorityType={props.editedIssue?.priorityType ?? PriorityType.Medium}
                            onPriorityTypeUpdate={(value) => props.onValueChange(value, "priorityType")}
                        />
                    </Form.Item>
                    <Form.Item label="Sprint" name="sprint">
                        <EditableSprintInput
                            sprint={props.editedIssue?.sprintDto}
                            onSprintUpdate={(value) => props.onValueChange(value, "sprintDto")}
                        />
                    </Form.Item>
                    <Form.Item label="Effort" name="points">
                        <EditableStoryPointsInput
                            storyPoints={props.editedIssue?.storyPoints}
                            onStoryPointsUpdate={(value) => props.onValueChange(value, "storyPoints")}
                        />
                    </Form.Item>
                    <Form.Item label="Assignee" name="assignee">
                        <IssueUserSelect
                            user={props.editedIssue.assignee}
                            projectKey={props.editedIssue.project?.projectKey!!}
                            onSelect={(value) => props.onValueChange(value, "assignee")}
                        />
                    </Form.Item>
                    <Form.Item label="Creator" name="creator">
                        <IssueUserSelect
                            user={props.editedIssue.assignee}
                            projectKey={props.editedIssue.project?.projectKey!!}
                            onSelect={(value) => props.onValueChange(value, "createdBy")}
                        />
                    </Form.Item>
                    <Form.Item label="Developer" name="developer">
                        <IssueUserSelect
                            user={props.editedIssue.developer}
                            projectKey={props.editedIssue.project?.projectKey!!}
                            onSelect={(value) => props.onValueChange(value, "developer")}
                        />
                    </Form.Item>
                    <Form.Item label="Peer Reviewer" name="peerReviewer">
                        <IssueUserSelect
                            user={props.editedIssue.peerReviewer}
                            projectKey={props.editedIssue.project?.projectKey!!}
                            onSelect={(value) => props.onValueChange(value, "peerReviewer")}
                        />
                    </Form.Item>
                    <Form.Item label="QA Reviewer" name="qaReviewer">
                        <IssueUserSelect
                            user={props.editedIssue.qaReviewer}
                            projectKey={props.editedIssue.project?.projectKey!!}
                            onSelect={(value) => props.onValueChange(value, "qaReviewer")}
                        />
                    </Form.Item>
                </>
            </Sider>
        </Form>
    );
}
