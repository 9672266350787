import React, { useEffect, useRef, useState } from "react";
import StatusType, { mapStatusTypeToFriendlyName } from "../../../../common/types/StatusType";
import BoardColumn from "./KanbanBoardColumn";
import { useGetIssueListQuery } from "../../api/IssueApi";
import { groupBy, orderBy } from "lodash";
import { useSelector } from "react-redux";
import {
    getFilteredIssues,
    getFilteredProject,
    getFilteredStatus,
    getFilteredTeam,
} from "../../selectors/BacklogSelectors";
import FilterDrawer from "../filters/FilterDrawer";
import { useShowViewOptionsDrawerListener } from "../../../../common/events";
import BacklogContextMenu from "../backlog-list/BacklogContextMenu";
import RightClickMenu from "../backlog-list/BacklogContextMenu";
import InvalidFilterState from "../InvalidFilterState";
import { Row } from "antd";

const statusMap = Object.values(StatusType)
    .filter(
        (x) =>
            x !== StatusType.Planning && x !== StatusType.Research && x !== StatusType.NotDone && typeof x === "number"
    )
    .map((x) => ({ label: mapStatusTypeToFriendlyName(x as StatusType), value: x }));

const KanbanBoardPage = () => {
    const containerRef = useRef<HTMLElement>();
    const rightClickMenu = useRef<typeof RightClickMenu>();
    const filteredTeam = useSelector(getFilteredTeam);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (containerRef.current) {
            setWidth(containerRef.current?.offsetWidth);
        }
        const getWidth = () => {
            if (containerRef.current) {
                setWidth(containerRef.current?.offsetWidth);
            }
        };
        window.addEventListener("resize", getWidth);
        return () => window.removeEventListener("resize", getWidth);
    }, []);

    useShowViewOptionsDrawerListener(() => {
        setIsDrawerOpen(true);
    });
    // TODO: Hook for fetching issue data
    // TODO: Filter to active sprint
    const filteredProject = useSelector(getFilteredProject);
    const filteredStatus = useSelector(getFilteredStatus);
    const {
        data,
        error,
        isLoading = true,
    } = useGetIssueListQuery(
        {
            projectKey: filteredProject?.key,
            teamKey: filteredTeam?.key,
            statusType: filteredStatus?.key as unknown as StatusType,
            filterToCurrentSprint: true,
        },
        {
            selectFromResult: ({ data, error, isLoading }) => ({
                data: orderBy(data, "order"),
                error,
                isLoading,
            }),
            pollingInterval: undefined,
            skip: !filteredTeam,
        }
    );

    const filteredIssues = groupBy(
        useSelector((state) => getFilteredIssues(state, data)),
        "status"
    );
    return (
        <div ref={containerRef as any}>
            <FilterDrawer
                open={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false);
                }}
            />
            {!filteredTeam ? (
                <div style={{ marginTop: 15 }}>
                    <InvalidFilterState />
                </div>
            ) : (
                <>
                    <div
                        style={{
                            paddingTop: 20,
                            paddingBottom: 20,
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                        }}
                    >
                        {statusMap.map((x, index) => (
                            <BoardColumn
                                key={x.value.toString()}
                                label={x.label}
                                isLast={index === statusMap.length - 1}
                                issues={filteredIssues[x.value] ?? []}
                                rightClickMenu={rightClickMenu?.current}
                                width={width / Math.floor(statusMap.length)}
                            />
                        ))}
                    </div>
                    <BacklogContextMenu teamKey={filteredTeam?.key} ref={rightClickMenu} />
                </>
            )}
        </div>
    );
};
export default KanbanBoardPage;
