import * as React from "react";
import "./NavMenu.css";
import { useNavigate } from "react-router";
import { Col, Layout, Menu, MenuProps, Row, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedTheme } from "../../UserPreferenceSelectors";
import { UserThemePreference } from "../../reducers/UserPreferenceSlice";
import { setCurrentCompany } from "../../reducers/CompanySlice";
import { resetBacklogState } from "../../../areas/backlog-management/reducers/BacklogSlice";
import { emptySplitApi } from "../../../store/emptyApi";
import { EvoLogo } from "./EvoLogo";
import { useGetSettingsTeamListQuery } from "../../../areas/settings/teams/api/TeamApi";
import NavActionMenu from "./NavActionMenu";
import { useGetFeatureListQuery } from "../../../store/generated/feature";
import Features from "../../enum/Features";
import { useMemo } from "react";
import { NavMenuProjectSelect } from "./NavMenuProjectSelect";
import { NavMenuTeamSelect } from "./NavMenuTeamSelect";
import { NavMenuSelectors } from "./NavMenuSelectors";

const { Header } = Layout;

const NavMenu = () => {
    const { data: features } = useGetFeatureListQuery();
    const { data: teams } = useGetSettingsTeamListQuery();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const activeUserThemePreference = useSelector(getSelectedTheme);

    const primaryMenuItems: MenuProps["items"] = useMemo(() => {
        const base = [
            {
                label: "Backlog",
                key: "/backlog-management/backlog",
            },
            {
                label: "Sprint",
                key: "/backlog-management/kanban",
            },
        ];
        if (features?.find((x) => x.featureKey === Features.Dashboards)?.isEnabled ?? false) {
            base.push({
                label: "Dashboards",
                key: "/dashboards",
            } as any);
        }
        if (features?.find((x) => x.featureKey === Features.Reports)?.isEnabled ?? false) {
            base.push({
                label: "Reports",
                key: "/reports",
            } as any);
        }
        return base;
    }, [features]);

    const onClick: MenuProps["onClick"] = (e) => {
        if (e.key.startsWith("nonav-")) {
            return;
        }
        navigate(e.key);
    };

    const onClickCornerLogo = () => {
        dispatch(emptySplitApi.util.resetApiState());
        dispatch(resetBacklogState());
        dispatch(setCurrentCompany(undefined));
        navigate("/company");
    };

    return (
        <Header style={{ paddingLeft: 0, paddingRight: 0, borderBottom: "1px solid rgba(253, 253, 253, 0.12)" }}>
            <Row>
                <Col
                    flex={1}
                    style={{
                        paddingLeft: 15,
                        lineHeight: "initial",
                        borderBottomStyle: "solid",
                        borderBottomWidth: 1,
                        backgroundColor:
                            activeUserThemePreference == UserThemePreference.Dark ? "rgb(20, 20, 20)" : "white",
                        borderBottomColor:
                            activeUserThemePreference == UserThemePreference.Dark
                                ? "rgba(253, 253, 253, 0.12)"
                                : "rgba(5, 5, 5, 0.06)",
                    }}
                >
                    <EvoLogo onClick={onClickCornerLogo} activeUserThemePreference={activeUserThemePreference} />
                </Col>
                <Col
                    flex={4}
                    style={{
                        maxHeight: "64px",
                        borderBottomStyle: "solid",
                        borderBottomWidth: 1,
                        backgroundColor:
                            activeUserThemePreference == UserThemePreference.Dark ? "rgb(20, 20, 20)" : "white",
                        borderBottomColor:
                            activeUserThemePreference == UserThemePreference.Dark
                                ? "rgba(253, 253, 253, 0.12)"
                                : "rgba(5, 5, 5, 0.06)",
                    }}
                >
                    {/* eslint-disable-next-line no-restricted-globals */}
                    {!location.pathname.includes("settings") &&
                    // eslint-disable-next-line no-restricted-globals
                    !location.pathname.includes("userpreferences") &&
                    // eslint-disable-next-line no-restricted-globals
                    !location.pathname.includes("login") &&
                    // eslint-disable-next-line no-restricted-globals
                    location.pathname !== "/" ? (
                        <NavMenuSelectors />
                    ) : undefined}
                </Col>
                <Col flex={4}>
                    <Menu onClick={onClick} mode="horizontal" items={primaryMenuItems} />
                </Col>
                <NavActionMenu onClick={onClick} />
            </Row>
        </Header>
    );
};

export default NavMenu;
