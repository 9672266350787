import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./custom.css";
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router";
import SettingsPage from "./areas/settings/SettingsPage";
import TenantListPage from "./areas/settings/tenants/components/TenantListPage";
import TenantDetailsPage from "./areas/settings/tenants/components/TenantDetailsPage";
import HomePage from "./areas/home/HomePage";
import LoginPage from "./areas/authentication/components/LoginPage";
import KanbanBoardPage from "./areas/backlog-management/components/kanban-view/KanbanBoardPage";
import BacklogListPage from "./areas/backlog-management/components/backlog-list/BacklogListPage";
import UserListPage from "./areas/settings/users/components/UserListPage";
import TeamListPage from "./areas/settings/teams/components/TeamListPage";
import NavMenu from "./common/navigation/navmenu/NavMenu";
import GanttViewPage from "./areas/backlog-management/components/GanttViewPage";
import { Card, ConfigProvider, Flex, Layout, theme } from "antd";
import IssueDetailsModal from "./areas/backlog-management/components/issue-details-modal/IssueDetailsModal";
import {
    useShowCreateIssueModalListener,
    useShowCreateReportModalListener,
    useShowImportIssueModalListener,
    useShowOnboardingTasksModalListener,
} from "./common/events";
import { IssueDto } from "./store/generated/issue";
import { FileImportModal } from "./common/components/FileImportModal";
import { ImportStatus } from "./common/types/ImportStatus";
import UserPreferencesPage from "./areas/user-preferences/components/UserPreferencesPage";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedTheme } from "./common/UserPreferenceSelectors";
import { UserThemePreference } from "./common/reducers/UserPreferenceSlice";
import TeamDetailsPage from "./areas/settings/teams/components/TeamDetailsPage";
import RoleListPage from "./areas/settings/roles/components/RoleListPage";
import RoleDetailsPage from "./areas/settings/roles/components/RoleDetailsPage";
import UserDetailsPage from "./areas/settings/users/components/UserDetailsPage";
import { getSelectedCompanyKey } from "./common/selectors/CompanySelectors";
import CompanySelectPage from "./areas/authentication/components/CompanySelectPage";
import AccessListPage from "./areas/settings/tenant-access/components/AccessListPage";
import AccessDetailsPage from "./areas/settings/tenant-access/components/AccessDetailsPage";
import { IssueWizardModal } from "./areas/backlog-management/components/issue-wizard/IssueWizardModal";
import ProjectListPage from "./areas/settings/projects/components/ProjectListPage";
import ProjectDetailsPage from "./areas/settings/projects/components/ProjectDetailsPage";
import LinkInvitePage from "./areas/deeplinks/components/LinkInvitePage";
import ReportsPage from "./areas/reports/components/ReportsPage";
import DashboardsPage from "./areas/dashboards/components/DashboardsPage";
import { getCurrentAccessType } from "./common/selectors/RoleSelectors";
import { AccessType } from "./common/types/AccessType";
import ReportModal from "./areas/reports/components/ReportModal";
import SprintListPage from "./areas/backlog-management/sprints/components/SprintListPage";
import SprintDetailsPage from "./areas/backlog-management/sprints/components/SprintDetailsPage";
import { useGetFeatureListQuery } from "./areas/settings/features/api/FeatureApi";
import FeatureListPage from "./areas/settings/features/components/FeatureListPage";
import OnboardingTasksModal from "./areas/onboarding/components/OnboardingTasksModal";
import { loadInitialAppState } from "./common/dispatch/App";

const { Content } = Layout;
const boxStyle: React.CSSProperties = {
    width: "100%",
    height: "calc(66vh - 64px)",
};
const ProtectedRoute = ({ isAllowed, isAccessBlocked, redirectPath = "/login", children }: any) => {
    const currentCompanyKey = useSelector(getSelectedCompanyKey);
    if (isAccessBlocked) {
        return <NoAccessComponent />;
    }
    if (!isAllowed) {
        return <Navigate to={currentCompanyKey ? redirectPath : "/company"} replace />;
    }
    return children ? children : <Outlet />;
};

function NoAccessComponent() {
    return (
        <Flex gap="middle" align="center" vertical>
            <Flex justify="center" align="center" style={boxStyle}>
                <Card title="No Access" bordered={false}>
                    <p>Your permissions for this workspace have been revoked. Contact an admin for assistance.</p>
                </Card>
            </Flex>
        </Flex>
    );
}

const App = () => {
    const currentAccessType = useSelector(getCurrentAccessType);
    const activeUserThemePreference = useSelector(getSelectedTheme);
    // TODO: This can be expedited by putting the user into a context
    const currentCompanyKey = useSelector(getSelectedCompanyKey);
    const { authStatus, isPending } = useAuthenticator((context) => [context.authStatus]);
    const { data: features } = useGetFeatureListQuery(undefined, {});
    useShowCreateIssueModalListener((params) => {
        setModalProps({ isOpen: true, issueKey: params.projectIssueKeyedLabel });
    });
    useShowImportIssueModalListener(() => {
        setImportStatus(ImportStatus.PendingInput);
    });
    useShowCreateReportModalListener(() => {
        setReportModalProps({ isOpen: true });
    });
    useShowOnboardingTasksModalListener(() => {
        setOnboardingTasksModalProps({ isOpen: true });
    });
    const [importStatus, setImportStatus] = useState<ImportStatus | null>(null);
    const [modalProps, setModalProps] = useState<{ isOpen: boolean; issueKey?: string; issue?: IssueDto }>({
        isOpen: false,
    });
    const [reportModalProps, setReportModalProps] = useState<{ isOpen: boolean }>({ isOpen: false });
    const [onboardingTasksModalProps, setOnboardingTasksModalProps] = useState<{ isOpen: boolean }>({ isOpen: false });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleImportModalClose = () => {
        setImportStatus(null);
    };

    useEffect(() => {
        const reloadAppContext = () => {
            dispatch(loadInitialAppState(navigate));
        };
        if (authStatus === "authenticated" && !currentCompanyKey) {
            reloadAppContext();
        }
    }, [authStatus, currentCompanyKey, dispatch, navigate]);

    return (
        <ConfigProvider
            theme={{
                algorithm:
                    activeUserThemePreference == UserThemePreference.Dark
                        ? theme.darkAlgorithm
                        : theme.defaultAlgorithm,
                token: {
                    // Seed Token
                    //colorPrimary: '#00b96b',
                    // borderRadius: 2,
                    // Alias Token
                    //colorBgContainer: '#edf1ff',
                },
            }}
        >
            {authStatus === "configuring" ? undefined : (
                <Layout className="layout">
                    {authStatus === "authenticated" && currentCompanyKey ? (
                        <>
                            <NavMenu />
                        </>
                    ) : undefined}
                    <Content
                        style={{
                            padding: "0 50px",
                            height:
                                location.pathname.includes("login") || location.pathname.includes("company")
                                    ? "calc(100vh)"
                                    : "calc(100vh - 64px)",
                            overflowY: "scroll",
                        }}
                    >
                        <Routes>
                            <Route path="/login" element={<LoginPage />} />
                            <Route
                                element={
                                    <ProtectedRoute
                                        isAllowed={authStatus === "authenticated"}
                                        isAccessBlocked={currentAccessType === AccessType.None}
                                    />
                                }
                            >
                                <Route path="/" element={<HomePage />} />
                                <Route path="/company" element={<CompanySelectPage />} />
                                <Route path="/home" element={<HomePage />} />
                                <Route path="/reports" element={<ReportsPage />} />
                                <Route path="/dashboards" element={<DashboardsPage />} />
                                <Route path="/backlog-management" element={<BacklogListPage />} />
                                <Route path="/backlog-management/kanban" element={<KanbanBoardPage />} />
                                <Route path="/backlog-management/backlog" element={<BacklogListPage />} />
                                <Route path="/backlog-management/gantt" element={<GanttViewPage />} />
                                <Route path="/backlog-management/sprints" element={<SprintListPage />} />
                                <Route path="/settings" element={<SettingsPage />} />
                                <Route path="/settings/customers" element={<TenantListPage />} />
                                <Route path="/settings/customer/details" element={<TenantDetailsPage />} />
                                <Route path="/settings/projects" element={<ProjectListPage />} />
                                <Route path="/settings/project/details" element={<ProjectDetailsPage />} />
                                <Route path="/settings/users" element={<UserListPage />} />
                                <Route path="/settings/access" element={<AccessListPage />} />
                                <Route path="/settings/access/details" element={<AccessDetailsPage />} />
                                <Route path="/settings/features" element={<FeatureListPage />} />
                                <Route path="/settings/user/details" element={<UserDetailsPage />} />
                                <Route path="/settings/roles" element={<RoleListPage />} />
                                <Route path="/settings/role/details" element={<RoleDetailsPage />} />
                                <Route path="/settings/sprints" element={<SprintListPage />} />
                                <Route path="/settings/sprint/details" element={<SprintDetailsPage />} />
                                <Route path="/settings/teams" element={<TeamListPage />} />
                                <Route path="/settings/team/details" element={<TeamDetailsPage />} />
                                {/*<Route exact path='/settings/boards' element={BoardSettingsPage} />*/}
                                <Route path="/userpreferences" element={<UserPreferencesPage />} />
                                <Route path="/link/invite" element={<LinkInvitePage />} />
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </Route>
                        </Routes>
                    </Content>
                </Layout>
            )}
            {modalProps.issueKey ? (
                <IssueDetailsModal
                    issueKey={modalProps?.issueKey}
                    isOpen={modalProps?.isOpen}
                    toggle={() => setModalProps({ ...modalProps, isOpen: !modalProps.isOpen, issueKey: undefined })}
                />
            ) : (
                <IssueWizardModal
                    isOpen={modalProps?.isOpen}
                    toggle={() => setModalProps({ ...modalProps, isOpen: !modalProps.isOpen, issue: undefined })}
                />
            )}
            <FileImportModal
                importStatus={importStatus}
                onImportStatusChanged={(importStatus) => setImportStatus(importStatus)}
                onHide={handleImportModalClose}
            />
            <ReportModal
                isOpen={reportModalProps.isOpen}
                onClose={() => setReportModalProps({ ...reportModalProps, isOpen: false })}
            />
            <OnboardingTasksModal
                isOpen={onboardingTasksModalProps.isOpen}
                onClose={() => setOnboardingTasksModalProps({ ...onboardingTasksModalProps, isOpen: false })}
            />
        </ConfigProvider>
    );
};

export default () => {
    return (
        <Authenticator.Provider>
            <App />
        </Authenticator.Provider>
    );
};
