import { Col, Menu, Tour } from "antd";
import * as React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { hasAppliedViewOptions } from "../../../areas/backlog-management/selectors/BacklogSelectors";
import { isAllowedToAccessSettings, isAllowedToSaveIssue } from "../../selectors/RoleSelectors";
import { useLocation } from "react-router";
import NavActionMenuBuilder from "./NavActionMenuBuilder";
import { useGetFeatureListQuery } from "../../../store/generated/feature";
import Features from "../../enum/Features";
import { userOnboardingSlice } from "../../reducers/UserOnboardingSlice";

type Props = {
    onClick: (e: any) => void;
};
const NavActionMenu = (props: Props) => {
    const location = useLocation();
    const hasAnyAppliedViewOptions = useSelector(hasAppliedViewOptions);
    const canSaveIssue = useSelector(isAllowedToSaveIssue);
    const canAccessSettings = useSelector(isAllowedToAccessSettings);
    const userBadgeCount = useSelector(userOnboardingSlice.selectors.getIncompleteChecklistItemCount);
    const { data: features } = useGetFeatureListQuery();

    const actionMenuItems = useMemo(() => {
        const result = new NavActionMenuBuilder().addIssueMenu(canAccessSettings, features ?? []);
        if (features?.find((x) => x.featureKey === Features.Reports)?.isEnabled) {
            result.addReportsMenu();
        }
        const isOnboardingEnabled = features?.find((x) => x.featureKey === Features.Onboarding)?.isEnabled;
        result
            .addSettingsMenu(canAccessSettings)
            .addUserPreferencesMenu(isOnboardingEnabled ? userBadgeCount : 0)
            .addFilterMenu(location, hasAnyAppliedViewOptions)
            .build();
        return result.build();
    }, [location.pathname, canAccessSettings, canSaveIssue, hasAnyAppliedViewOptions, features]);

    return (
        <Col flex="auto">
            <Menu
                selectable={false}
                onClick={props.onClick}
                mode="horizontal"
                items={actionMenuItems}
                style={{ justifyContent: "flex-end" }}
            />
        </Col>
    );
};

export default NavActionMenu;
